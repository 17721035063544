import React, {Fragment as F} from 'react'
import HashLink from 'react-router-hash-link'
import { Helmet } from "react-helmet" 


class Netting extends React.Component {

  render() {
    return (
      <F>
        <Helmet>
          <title>Salus Safety | Netting</title>
          <meta name='description' content='Salus HDPE bird netting and bird spike systems. Sports Net systems from simple field barrier nets up to indoor high impact Throws net systems' />
          <meta name='keywords' content='Sports Netting, Barrier Netting, Throws net, Ball Stop, Ceiling Netting, Gym Net, Bird Proofing, Bird Netting, Bird Deterent, Bird Spikes' />
        </Helmet>
        <a className='anchor' name='netting' />
        <div className='landing-area-2'>
          <div className='has-background-white content-page'>
            <div>
              <h1 className='has-text-grey-darker has-text-centered is-size-8 netting'>NETTING</h1>
              <div className='line is-size-8'></div>
            </div>
            <div className='links-area'>
              <div>
                <a href='#bird-netting'><h2 className='has-text-underlined has-text-black is-size-4'><b>Bird Netting</b></h2></a>
                <p>Prevent birds fouling your business assets, products/stock, employees and the public</p>
              </div>
              <div>
                <a href='#sports-netting'><h2 className='has-text-underlined has-text-black is-size-4'><b>Sports Netting</b></h2></a>
                <p>Create spaces for activities - permanent or temporary structures or nets, curtains or enclosures</p>
              </div>
              <div>
                <a href='#barrier-netting-sports'><h2 className='has-text-underlined has-text-black is-size-4'><b>Barrier Netting</b></h2></a>
                <p><a href='#barrier-netting-sports' className='has-text-black has-text-underlined'><strong>Sports:</strong></a> Prevent balls from reaching unwanted places - golf balls hitting cars are a considerable hazard
                    <br/><a href='#barrier-netting-industrial' className='has-text-black has-text-underlined'><strong>Industrial:</strong></a> Prevent product leaving the production line and harming people</p>
              </div>
              <div>
                <a href='#perimeter-netting'><h2 className='has-text-underlined has-text-black is-size-4'><b>Perimeter Netting</b></h2></a>
                <p>Prevent access to places where harm or damage can occur - water treatment plants</p>
              </div>
            </div>
            
            <div className="content-page">
              <a className='anchor' name='bird-netting'/>
              <h1 id='test' className='has-text-grey-darker has-text-centered is-size-8 netting'>Bird Netting</h1>
              <div className='line is-size-8'></div>
              <div className="image-bird-netting"></div>
              <div className='solutions-blurb'>
                <p >Bird droppings are a health hazard and can deface product costing your business money and potentially harming your reputation. <br/> <br/> 
                Salus Bird Netting prevents birds from perching on internal roof or wall structures; bird droppings no longer reach work areas, staff or product below. <br/><br/>
                Salus can supply and install Bird Netting for a variety of buildings, including aircraft hangers, warehouses, and medical equipment and food production facilities.  We also cover roller doors, canopy undersides, and places where the entry, perching, or nesting of birds is an issue. <br/> <br/>   
                The benefits of a Salus Bird Netting system include:
                </p>
                <div className='content'>
                  <ul>
                    <li><p>atheistically pleasing - the netting virtually disappears into the background</p></li>
                    <li><p>easy to accommodate changes to the building structure, for example, replacing light bulbs</p></li>
                    <li><p>easy to maintain</p></li>
                    <li><p>problem-solving for tricky situations - Salus provide solutions, not just a product</p></li>
                    <li><p>cost-effective - on a cost/sqm basis, you’d be hard pressed to find a better deal</p></li>
                    <li><p>no more perching, droppings, nesting, dead or alive birds</p></li>
                    <li><p>installation can take place during the build or retro-fitted to existing structures</p></li>
                  </ul>
                  <p>Include bird netting in your build plan and birds will never have an opportunity to turn your building into a roosting site. Salus will give you the solution that is best suited to your situation.</p>
                </div>
                <a href="#netting" target="_self"><h1 className='has-text-centered'>Return to top of page</h1></a>
              </div>
              <div className='line is-size-8'></div>
            </div>


            <div className="content-page">
              <a className='anchor' name='sports-netting'/>
              <h1 id='test' className='has-text-grey-darker has-text-centered is-size-8 netting'>Sports Netting</h1>
              <div className='line is-size-8'></div>
              <div className="image-netting"></div>
              <div className='solutions-blurb'>
                <p>Salus has designed and installed a number of sports netting solutions for various clients and organisations throughout New Zealand ranging from the simple to the very complex.
                </p>
                <div className='content'>
                  <ul>
                    <li><p>Hammer throw cages and practice spaces, both indoor and outdoor, for our elite athletes in the North and South Island</p></li>
                    <li><p>Water polo, indoor cricket and indoor rugby net systems</p></li>
                    <li><p>Curtains and permanent partition systems</p></li>
                    <li><p>Net systems providing ceiling/wall protection and preventing building damage</p></li>
                  </ul>
                </div>
                <a href="#netting" target="_self"><h1 className='has-text-centered'>Return to top of page</h1></a>
              </div>
              <div className='line is-size-8'></div>
            </div>

            <div className="content-page">
              <a className='anchor' name='barrier-netting-sports'/>
              <h1 id='test' className='has-text-grey-darker has-text-centered is-size-8 netting'>Barrier Netting for Sports</h1>
              <div className='line is-size-8'></div>
              <div className="image-barriers-netting"></div>
              <div className='solutions-blurb'>
               <p>Create a barrier that prevents balls (rugby/soccer balls, golf balls) entering areas where they may become a hazard. <br/> <br/>
                Golf balls can smash windscreens and become a serious hazard to traffic. <br/><br/>
                We can work with an engineer to design and install a system that will create a barrier preventing balls entering areas they shouldn't and minimising or eliminating the risk for serious harm.
               </p>
                <a href="#netting" target="_self"><h1 className='has-text-centered'>Return to top of page</h1></a>
              </div>
              <div className='line is-size-8'></div>
            </div>

            <div className="content-page">
              <a className='anchor' name='barrier-netting-industrial'/>
              <h1 id='test' className='has-text-grey-darker has-text-centered is-size-8 netting'>Barrier Netting for Industrial</h1>
              <div className='line is-size-8'></div>
              <div className="image-barrieri-netting"></div>
              <div className='solutions-blurb'>
              <p>Create a barrier to prevent harm in the workplace - prevent product leaving the production line and entering the space occupied by people.</p>
                <a href="#netting" target="_self"><h1 className='has-text-centered'>Return to top of page</h1></a>
              </div>
              <div className='line is-size-8'></div>
            </div>

            <div className="content-page">
              <a className='anchor' name='perimeter-netting'/>
              <h1 id='test' className='has-text-grey-darker has-text-centered is-size-8 netting'>Perimeter Netting</h1>
              <div className='line is-size-8'></div>
              <div className="image-perimeter-netting"></div>
              <div className='solutions-blurb'>
                <p>Salus Perimeter Netting is ideal for many different industries and council facilities. In water management, perimeter netting can prevent the contamination of fresh water tanks by keeping birds, litter and other projectiles out. It is also useful for waste management plants, council facilities, or any other organisation who needs to keep their property free of the public’s litter or interference. <br/><br/>
                  Salus can adapt their perimeter nets to your building and situation, working with you to solve your challenges.
                </p>
            
                <a href="#netting" target="_self"><h1 className='has-text-centered'>Return to top of page</h1></a>
              </div>
              <div className='line is-size-8'></div>
            </div>

          </div> 
        </div>
        
      </F>
    )
  }
}

export default (Netting)