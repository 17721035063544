import React, {Fragment as F} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {logoutUser} from '../actions/logout'


class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showBurger: false
    }
    this.toggleBurger = this.toggleBurger.bind(this)
  }
  toggleBurger() {
    this.setState({showBurger: !this.state.showBurger})
  }
  render() {
    const {auth, logout} = this.props
    const {showBurger} = this.state
    return (
      <nav className="navbar is-fixed-top navbar-css">
        <div className="navbar-brand">
          <div className='image-icon' style={{width: 237 +'px', height: 96 +'px'}} alt="Icon"/>
            <div className='column nav-column'>
              <span onClick={this.toggleBurger} className={`navbar-burger burger ${showBurger ? 'is-active': ''}`} data-target="hamburger">
                <span className='has-text-white' aria-hidden="true"></span>
                <span className='has-text-white' aria-hidden="true"></span>
                <span className='has-text-white' aria-hidden="true"></span>
              </span>
            </div>
          </div>
            <div id="hamburger" className={`navbar-menu ${showBurger ? "is-active " : ''}`}>
              <div className={`column nav-column ${showBurger ? "nav-column-down":""}`}>
                <a onClick={this.toggleBurger} href='/' className="navbar-item"><h1 className="has-text-white has-text-weight-bold is-size-6-mobile is-size-5">HOME</h1></a>
                <a onClick={this.toggleBurger} href='/#solutions' className="navbar-item"><h1 className="has-text-white has-text-weight-bold is-size-6-mobile is-size-5">SOLUTIONS</h1></a>
                <a onClick={this.toggleBurger} href='/#why' className="navbar-item"><h1 className="has-text-white has-text-weight-bold is-size-6-mobile is-size-5">WHY SALUS?</h1></a>
                <a onClick={this.toggleBurger} href='/#contact' className="navbar-item"><h1 className="has-text-white has-text-weight-bold is-size-6-mobile is-size-5">CONTACT</h1></a>
            </div>
          </div>
        </nav>
      
      
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logoutUser())
  }
}

const mapStateToProps = ({auth}) => {
  return {auth}
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav)
