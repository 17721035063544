import React, {Fragment as F} from 'react'
import { Helmet } from "react-helmet" 

class FRS extends React.Component {

  render() {
    return (
      <F>
        <Helmet>
          <title>Salus Safety | FRS</title>
          <meta name='description' content='Full Service, "One stop shop" suppliers of practical Height Safety systems' />
          <meta name='keywords' content='Height Safety, Roof Anchors, Fall Restraint, Roof Walkways, Guardrail, Ladder Access' />
        </Helmet>
        <a className='anchor' name='FRS' />
        <div className='landing-area-2'>
          <div className='has-background-white content-page'>
            <div>
              <h1 className='has-text-grey-darker has-text-centered is-size-8 netting'>Height Safety Solutions</h1>
              <div className='line is-size-8'></div>
            </div>
            <div className='links-area'>
              <div>
                <a href='#frs'><h2 className='has-text-underlined has-text-black is-size-4'><b>Fall Restraint Systems</b></h2></a>
                <p>Safety line to be used in conjunction with an appropriate harness, we include Annual Recertification, Auditing &amp; System remediation in our Service packages</p>
              </div>
              <div>
                <a href='#frs'><h2 className='has-text-underlined has-text-black is-size-4'><b>Handrail</b></h2></a>
                <p>Provides a safe working environment</p>
              </div>
              <div>
                <a href='#frs'><h2 className='has-text-underlined has-text-black is-size-4'><b>Ladders</b></h2></a>
                <p>Safe access to the roof or elevated surface</p>
              </div>
              <div>
                <a href='#frs'><h2 className='has-text-underlined has-text-black is-size-4'><b>Roof Access</b></h2></a>
                <p>Via ladder - external or internal, including hatches from internal ladders, ladders with handrail or ladder lines</p>
              </div>
              <div>
                <a href='#frs'><h2 className='has-text-underlined has-text-black is-size-4'><b>Walkway</b></h2></a>
                <p>A clear, safe path to critical infrastructure, protect roof from damage - with or without handrail, and adjacent to a Fall Restraint System</p>
              </div>
              <div>
                <a href='#frs'><h2 className='has-text-underlined has-text-black is-size-4'><b>Overhead Rail Systems</b></h2></a>
                <p>Work from height with a system that is both easy to use and out of the way</p>
              </div>
              <div>
                <a href='#frs'><h2 className='has-text-underlined has-text-black is-size-4'><b>Engineered Solution</b></h2></a>
                <p>Will provide a specific solution to your problem</p>
              </div>
            </div>
            
            <div className="content-page">
              <a className='anchor' name='frs'/>
              <h1 className='has-text-grey-darker has-text-centered is-size-8 netting'>Height Safety</h1>
              <div className='line is-size-8'></div>
              <div className='solutions-blurb'>
                <p>We are certified installers of the full range of Sayfa products in New Zealand</p>
                <br/>
                <p>All the Sayfa products:</p>
                <br/>
                <ul>
                  <li>Anchors</li>
                  <li>Fold Down Ladders</li>
                  <li>Guardrails &amp; Walkway</li>
                  <li>Rigid Rails</li>
                  <li>Rung Ladders</li>
                  <li>Stairs &amp; Platforms</li>
                  <li>Static Lines</li>
                  <li>Step Ladders &amp; Bridges</li>
                </ul>
                <br/>
                <p>Can be found by clicking <a target='_black' href="https://sayfa.com.au/">here</a></p>
                <a href="#civil-works" target="_self"><h1 className='has-text-centered'>Return to top of page</h1></a>
              </div>
              <div className='line is-size-8'></div>
            </div>
          </div> 
        </div>
        
      </F>
    )
  }
}

export default (FRS)