import React, {Fragment as F} from 'react'
import { Helmet } from "react-helmet" 

class Home extends React.Component {

  render() {
    return (
      <F>
        <Helmet>
          <title>Salus Safety</title>
          <meta name='description' content='Nationwide Installer of Height Safety, Bird Netting, Sports Netting Systems and FRP Civil Structures' />
          <meta name='keywords' content='Bird Netting, Sports Netting, FRP Civil Structures, Height Safety' />
        </Helmet>
        <a className='anchor' name='' />
        <div className='flexbox landing-area is-size-8 is-fullscreen'>
          <div className="flexbox-column">
            <h1 className='has-text-white la-1'>Height Safety Solutions</h1>
            <h1 className='has-text-white la-2'>Netting Solutions</h1>
            <h1 className='has-text-white la-3'>Civil Works Solutions</h1>
            <a href='#solutions'><img className='la-5' src='/images/Downward-Arrow.webp'/></a>
          </div>
        </div>
      
        <div className='tiles has-background-white is-size-8'>
          <h1 className='has-text-grey-darker has-text-centered has-space-above-half'>SOLUTIONS</h1>
          <a className='anchor-1' name='solutions' />
          <div className='line'></div>
          <div className="flexbox-row content">
            <div className='has-border-one'>
              <div className='image-safety-image' />
                <a href='/height-safety-solutions'><h2 className='has-text-black is-marginless has-text-centered is-size-3 has-text-underlined'>Height Safety</h2></a>
                <h3 className='has-text-gray-darker has-text-centered is-size-5'>Design, Build and Install:</h3>
                <div className='margin-ul-tiles'>
                  <ul className='is-size-6'>
                    <li><p>Fall Restraint Systems</p></li>
                    <li><p>Handrails</p></li>
                    <li><p>Ladders</p></li>
                    <li><p>Roof Access</p></li>
                    <li><p>Walkway</p></li>
                    <li><p>Overhead Rail Systems</p></li>
                    <li><p>Engineered Solutions as Required</p></li>
                  </ul>
                </div>
              </div>
              <div className='has-border-one'>
                <div className='image-pigeon-net' />
                <a href='/netting'><h2 className='has-text-black is-marginless has-text-centered is-size-3 has-text-underlined'>Netting</h2></a>
                <h3 className='has-text-gray-darker has-text-centered is-size-5'>Design, Build and Install:</h3>
                <div className='margin-ul-tiles'>
                  <ul className='is-size-6'>
                    <li><p>Bird Netting</p></li>
                    <li><p>Sports Netting</p></li>
                    <li><p>Sports Enclosures</p></li>
                    <li><p>Sports Curtains</p></li>
                    <li><p>Barrier Netting</p></li>
                    <li><p>Perimeter Netting</p></li>
                    <li><p>Woven Mesh</p></li>
                  </ul>
                </div>
              </div>
              <div className='has-border-one'>
                <div className='image-oraki-walkway' />
                <a href='/civil'><h2 className='has-text-black  is-marginless has-text-centered is-size-3 has-text-underlined'>Civil Works</h2></a>
                <h3 className='has-text-gray-darker has-text-centered is-size-5'>Design, Build and Install:</h3>
                <div className='margin-ul-tiles'>
                  <ul className='is-size-6'>
                    <li><p>Over Ground Walkways &amp; Bridges</p></li>
                    <li><p>FRP Formwork &amp; Trenchlids</p></li>
                    <li><p>Noise Wall &amp; Cladding</p></li>
                    <li><p>Luminescent FRP Systems</p></li>
                  </ul>
                </div>
            </div>
          </div>
        </div>

        <div className='flexbox-row-1'>
         <a className='anchor-2' name='why' />
            <div className='box-flex '>
              <h1 className='is-size-8 is-paddingless has-text-grey-darker has-text-centered has-space-above-half '>Why Salus?</h1>
              <div className='line is-size-8'></div>
              <p className='why-text'><strong>Solutions &amp; Services</strong><br/>We design, build and 
              maintain practical solutions. We work hard to eliminate the potential for confusion and misuse. 
              If you need help to maintain the systems you have we will give you the right advice when repairs 
              are needed to those systems.
              <br/><br/><strong>Netting</strong><br/>Salus can provide bird netting solutions to prevent those 
              pesky birds fouling on your products, assets, employees and customers. If you need a sports netting
              solution - we design and build. Barrier netting to keep your equipment enclosed and free from 
              interference or prevent that stray ball from landing where it shouldn't.</p>

          </div>
          <div className='box-flex walkway-img padding-below-half is-hidden-mobile'>
          </div>
        </div>
        <div>
          <a className='anchor-2' name='contact' id='contact' />
          <h1 className='is-size-8 is-paddingless has-text-grey-darker has-text-centered has-space-above-half '>Contact</h1>
          <div className='line is-size-8'></div>
         <div className="flexbox-row-1 margin-below-half">
            <div className="contact-box-1">
              <h1 className="has-text-black is-size-4">Head Office</h1>
              <h1 className="has-text-black is-size-6"><strong>Postal Address:</strong></h1>
              <p>PO Box 40585</p>
              <p>Upper Hutt</p>
              <p>Wellington 5140</p>
            </div>
            <div className="contact-box-2">
              <h1 className="has-text-black is-size-4">Inquiries</h1>
              <p>If you have inquiries or questions please call or email</p>
              <p> <strong>Email:</strong> jobs@salussafety.co.nz</p>
              <p><strong>Phone:</strong> 0800 472 587</p>
            </div>
          </div>
        </div>
      </F>
    )
  }
}

export default (Home)
