import React, {Fragment as F} from 'react'
import { Helmet } from "react-helmet" 


class Civil extends React.Component {

  render() {
    return (
      <F>
        <Helmet>
          <title>Salus Safety | Civil</title>
          <meta name='description' content='In Partnership with Gracol Composites we provide overground walkway solutions especially for coastal installations' />
          <meta name='keywords' content='Overground Walkway, Walkway, Bridges, Decking, FRP Mesh, Trench Lids, Noise Walls, Motorway Barriers' />
        </Helmet>
        <a className='anchor' name='civil-works' />
        <div className='landing-area-2'>
          <div className='has-background-white content-page'>
            <div>
              <h1 className='has-text-grey-darker has-text-centered is-size-8 netting'>Civil Works</h1>
              <div className='line is-size-8'></div>
            </div>
            <div className='links-area'>
              <div>
                <a href='#walkway-bridges'><h2 className='has-text-underlined has-text-black is-size-4'><b>Over Ground Walkways &amp; Bridges</b></h2></a>
                <p>Low maintenance <strong>FRP</strong> (Fibreglass Reinforced Polymer) walkways and bridges</p>
              </div>
              <div>
                <a href='#trenchlid'><h2 className='has-text-underlined has-text-black is-size-4'><b>FRP Formwork &amp; Trenchlids</b></h2></a>
                <p>Load rated and custommade</p>
              </div>
              <div>
                <a href='noise'><h2 className='has-text-underlined has-text-black is-size-4'><b>Noise Wall &amp; Cladding</b></h2></a>
                <p>"R" rated lightweight noise wall and coloured cladding options</p>
              </div>
              <div>
                <a href='luminescent'><h2 className='has-text-underlined has-text-black is-size-4'><b>Luminescent FRP Systems</b></h2></a>
                <p>Walk Safe - Night Glow Systems</p>
              </div>
            
            </div>
            
            <div className="content-page">
              <a className='anchor' name='walkway-bridges'/>
              <h1 className='has-text-grey-darker has-text-centered is-size-8 netting'>Walkways &amp; Bridges</h1>
              <div className='line is-size-8'></div>
              <div className="image-walkway"></div>
              <div className='solutions-blurb'>
                <p>Working with Gracol Composites we design, fabricate and install a complete range of FRP Walkways and Bridges</p>
                <h3 className='has-text-gray-darker has-text-centered is-size-4'>Project:</h3>
                <h3 className='has-text-gray-darker has-text-centered is-size-5'>Orakei Lagoon Footbridge Redevelopment</h3>
                <p>Custommade FRP Decking and Minimesh Grating used over wooden bearers. Job included multipule curves and a new sloping bridge. Total Walkway installed: 735m long &amp; 4.6m wide. <a target="_blank" href='https://www.facebook.com/nztaakl/videos/469248010590407/'>Public View</a></p>
                <br />
                <div className='flexbox-row'>
                <div className="orakei-photo-one"></div>
                <div className="orakei-photo-two"></div>
                </div>
                <a href="#civil-works" target="_self"><h1 className='has-text-centered'>Return to top of page</h1></a>
              </div>
              <div className='line is-size-8'></div>
            </div>

            <div className="content-page">
              <a className='anchor' name='trenchlid'/>
              <h1 className='has-text-grey-darker has-text-centered is-size-8 netting'>FRP Formwork &amp; Trenchlids</h1>
              <div className='line is-size-8'></div>
              <div className="image-trench"></div>
              <div className='solutions-blurb'>
                <p>Working with Gracol Composites we design, fabricate and install a complete range of FRP Formwork &amp; Trenchlids</p>
                <h3 className='has-text-gray-darker has-text-centered is-size-4'>Projects:</h3>
                <h3 className='has-text-gray-darker has-text-centered is-size-5'>Westfield Newmarket Substation Trenchlids</h3>
                <p>Design, Fabricate &amp; Install Trenchlids for the three Substations.<a href='https://www.gracolcomposites.co.nz/download/trench-technical-brochure.pdf'> Gracol Brochure</a></p>
                <br />
                <h3 className='has-text-gray-darker has-text-centered is-size-5'>Bridge Formwork</h3>
                <p>Design, Fabricate &amp; Install load rated FRP Formwork for bridge span concreting.<a href='https://www.gracolcomposites.co.nz/download/trench-technical-brochure.pdf'> Gracol Brochure</a></p>
                <br />
                <a href="#civil-works" target="_self"><h1 className='has-text-centered'>Return to top of page</h1></a>
              </div>
              <div className='line is-size-8'></div>
            </div>

            <div className="content-page">
              <a className='anchor' name='noise'/>
              <h1 className='has-text-grey-darker has-text-centered is-size-8 netting'>Noise Wall &amp; Cladding</h1>
              <div className='line is-size-8'></div>
              <div className='solutions-blurb'>
                <p className='is-size-4 has-text-centered'>Almost ready to launch!</p>
                <p>Working with Gracol Composites we design, fabricate and install a complete range of FRP Noise Walls and Wall Cladding. We are developing a range of "R" rated noise wall panels and motorway wall cladding.</p>
                <br />
                <a href="#civil-works" target="_self"><h1 className='has-text-centered'>Return to top of page</h1></a>
              </div>
              <div className='line is-size-8'></div>
            </div>

            <div className="content-page">
              <a className='anchor' name='luminescent'/>
              <h1 className='has-text-grey-darker has-text-centered is-size-8 netting'>Luminescent FRP Systems</h1>
              <div className='line is-size-8'></div>
              <div className='solutions-blurb'>
                <p className='is-size-4 has-text-centered'>Almost ready to launch!</p>
                <p>Gracol Composites Walkway and Bridge Systems that glow in the dark. No electricity needed - a days sunlight gives a safe glow all night.</p>
                <br />
                <a href="#civil-works" target="_self"><h1 className='has-text-centered'>Return to top of page</h1></a>
              </div>
              <div className='line is-size-8'></div>
            </div>
          </div> 
        </div>
        
      </F>
    )
  }
}

export default (Civil)