import React from 'react'
import {BrowserRouter as BRouter, Route, Switch} from 'react-router-dom'

import Nav from './Nav'
import Home from './Home'
import Netting from './Netting'
import FRS from './FRS'
import Civil from './Civil'


export function App({auth}) {
  return (
    <BRouter>
      <div className="App">
        <Nav />
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route path="/netting" component={Netting}/>
          <Route path="/height-safety-solutions" component={FRS} />
          <Route path="/civil" component={Civil} />
        </Switch>
      </div>
    </BRouter>
  )
}


export default App
